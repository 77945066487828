import * as Sentry from '@sentry/node'
import ServerCookies, { GetOption, SetOption } from 'cookies'
import { IncomingMessage, ServerResponse } from 'http'
import BrowserCookies, { CookieAttributes } from 'js-cookie'
import { v1 as uuid } from 'uuid'
import { PatchedUserSemiPersistentSession, UserSemiPersistentSession } from '../amplitude/amplitude'
import { addYears } from 'date-fns'

import size from 'lodash.size'
import { isServer } from '../../util/config'

const USER_LS_KEY = 'user'

export default class UniversalCookiesManager {
    private readonly defaultServerOptions: SetOption = {
        httpOnly: false,
        expires: addYears(new Date(), 10),
    }
    private readonly defaultBrowserOptions: CookieAttributes = {
        expires: 365 * 10,
    }

    constructor(
        private readonly req: IncomingMessage = null,
        private readonly res: ServerResponse = null,
        private readonly readonlyCookies: Cookies = null,
    ) {}

    replaceUserData(
        newUserData: UserSemiPersistentSession,
        serverOptions = this.defaultServerOptions,
        browserOptions: CookieAttributes = this.defaultBrowserOptions,
    ): void {
        try {
            if (!isServer) {
                const browserCookies = BrowserCookies.withConverter({
                    write: function (value: string, name: string) {
                        return value
                    },
                })
                browserCookies.set(USER_LS_KEY, JSON.stringify(newUserData), browserOptions)
            } else {
                const serverCookies = new ServerCookies(this.req, this.res)
                if (this.req && this.res) {
                    serverCookies.set(USER_LS_KEY, JSON.stringify(newUserData), serverOptions)
                }
            }
        } catch (e) {
            Sentry.captureException(e)
        }
    }

    initUserData(): UserSemiPersistentSession {
        const deviceId: string = uuid()
        const userData: UserSemiPersistentSession = {
            id: deviceId,
            deviceId,
        }

        this.replaceUserData(userData)

        return userData
    }

    getUserData(serverOptions?: GetOption): UserSemiPersistentSession {
        let rawUserData: string

        if (!isServer) {
            rawUserData = BrowserCookies.get(USER_LS_KEY)
        } else {
            const serverCookies = new ServerCookies(this.req, this.res)
            if (this.req && this.res) {
                rawUserData = serverCookies.get(USER_LS_KEY, serverOptions)
            } else if (this.readonlyCookies) {
                rawUserData = this.readonlyCookies?.[USER_LS_KEY]
            }
        }

        if (typeof rawUserData === 'undefined') {
            return this.initUserData()
        }

        try {
            const userData: UserSemiPersistentSession = JSON.parse(rawUserData)

            if (!userData) {
                return this.initUserData()
            } else {
                return userData
            }
        } catch (e) {
            Sentry.withScope((scope) => {
                scope.setExtra('rawUserData', rawUserData)
                Sentry.captureException(e)
            })
            console.error(e)
            return this.initUserData()
        }
    }

    patchUserData(patch: PatchedUserSemiPersistentSession): void {
        this.replaceUserData({
            ...this.getUserData(),
            ...patch,
        })
    }
}

type Cookies = {
    [key: string]: any
}

export const deleteAllCookies = (): void => {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < size(cookies); i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
    }
}
