import pack from '../../package.json'

const config: Config = {
    env: process.env.NEXT_PUBLIC_ENV || 'development',
    nodeEnv: process.env.NODE_ENV || 'development',
    version: process.env.NEXT_PUBLIC_VERSION || pack?.version || 'no-version',
    gqlUri: process.env.NEXT_PUBLIC_API_URL || 'http://localhost:1337/graphql',
    googleApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY || null,
    mixPanel: process.env.MIXPANEL,
    auth: {
        appUrl: process.env.NEXT_PUBLIC_APP_URL,
        googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
        facebookClientId: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
    },
    sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    amplitudeKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
}

export const isDev = config.env === 'development'
export const isProd = config.env === 'production'
export const isServer = typeof window === 'undefined'

export default config

type Config = {
    env: string
    nodeEnv: string
    version: string
    gqlUri: string
    googleApiKey: string
    mixPanel: string
    sentryDsn: string
    auth: {
        appUrl: string
        googleClientId: string
        facebookClientId: string
    }
    amplitudeKey: string
}
