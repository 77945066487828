import { useState, FC, useEffect } from 'react'
import config, { isProd, isServer } from '../../util/config'

export const DebugHelper: FC = () => {
    const [isDebug, setIsDebug] = useState(!isProd)

    useEffect(() => {
        if (localStorage.getItem('debug')) {
            setIsDebug(true)
        }
    }, [])
    if (!isDebug) return null

    return (
        <div className="fixed bottom-0 left-0 z-50 flex flex-col items-center justify-center p-1 mb-8 text-2xl font-bold text-white bg-black">
            <div className="block sm:hidden">M</div>
            <div className="hidden text-lg sm:block md:hidden">sm</div>
            <div className="hidden text-lg md:block lg:hidden">md</div>
            <div className="hidden text-lg lg:block xl:hidden">lg</div>
            <div className="hidden text-lg xl:block">xl</div>
            <div className="text-xs">{config.env}</div>
            <div className="text-xs">{config.version}</div>
        </div>
    )
}
