import Cookie from 'js-cookie'
import { isServer } from './config'

export const getJwt = (): string | null => {
    if (!isServer) {
        if (localStorage) {
            const token = localStorage.getItem('jwt')
            return token || null
        } else {
            const token = Cookie.get('jwt')
            return token || null
        }
    }
    return null
}

export const storeJwt = (jwt: string): void => {
    localStorage ? localStorage.setItem('jwt', jwt.toString()) : Cookie.set('jwt', jwt.toString())
}

export const removeJwtFromStore = (): void => {
    localStorage ? localStorage.removeItem('jwt') : Cookie.remove('jwt')
}

export const getCookieConsentStatus = (): boolean => {
    if (localStorage) {
        const consent = localStorage.getItem('cookieConsent')
        return !!consent || false
    } else {
        const consent = Cookie.get('cookieConsent')
        return !!consent || false
    }
}

export const setCookieConsent = (status: boolean): void => {
    localStorage
        ? localStorage.setItem('cookieConsent', status.toString())
        : Cookie.set('cookieConsent', status.toString())
}
