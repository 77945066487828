import * as Sentry from '@sentry/node'
import { AmplitudeClient, Identify, Callback, LogReturn } from 'amplitude-js'
import config, { isProd, isServer } from '../../util/config'
import UniversalCookiesManager from '../cookiesManager/UniversalCookiesManager'

export enum AMPLITUDE_ACTIONS {
    CLICK = 'click', // When an element is clicked (mouse) or tapped (screen, mobile)
    SELECT = 'select', // When an element is selected (checkbox, select input, multi choices)
    REMOVE = 'remove', // When an element is removed/delete
    OPEN = 'open', // When an element is opened
    CLOSE = 'close', // When an element is closed
}

export enum AMPLITUDE_PAGES {
    DEMO_HOME_PAGE = 'demo-home',
    PREVIEW_PRODUCT_PAGE = 'preview-product',
    TERMS_PAGE = 'terms',
    PRIVACY_PAGE = 'privacy',
    TEMPLATE_SSG_PAGE = 'template-ssg',
    TEMPLATE_SSR_PAGE = 'template-ssr',
}

type GetAmplitudeInstanceProps = {
    userId: string
}

export const getAmplitudeInstance = (props: GetAmplitudeInstanceProps): AmplitudeClient | null => {
    if (!isServer) {
        const { userId } = props

        const amplitude = require('amplitude-js')
        const amplitudeInstance: AmplitudeClient = amplitude.getInstance()
        amplitudeInstance.init(config.amplitudeKey, null, {
            userId: userId.toString(),
            logLevel: isProd ? 'DISABLE' : 'WARN',
            includeGclid: true,
            includeReferrer: true,
            includeUtm: true,
            onError: ((error): void => {
                Sentry.captureException(error)
                console.error(error)
            }) as () => void,
            sameSiteCookie: 'Strict',
            cookieExpiration: 365,
        })

        amplitudeInstance.setVersionName(config.version)

        // We're only doing this when detecting a new session, as it won't be executed multiple times for the same session anyway, and it avoids noise
        if (amplitudeInstance.isNewSession()) {
            const visitor: Identify = new amplitudeInstance.Identify()
        }
        return amplitudeInstance
    } else {
        return null
    }
}

export const sendWebVitals = (report: NextWebVitalsMetricsReport): void => {
    try {
        const amplitude = require('amplitude-js')
        const amplitudeInstance: AmplitudeClient = amplitude.getInstance()
        const universalCookiesManager = new UniversalCookiesManager()
        const userData: UserSemiPersistentSession = universalCookiesManager.getUserData()

        amplitudeInstance.init(config.amplitudeKey, null, {
            userId: userData.id,
            logLevel: isProd ? 'DISABLE' : 'WARN',
            includeGclid: false,
            includeReferrer: true,
            includeUtm: true,
            onError: ((error): void => {
                Sentry.captureException(error)
                console.error(error)
            }) as () => void,
            sameSiteCookie: 'Strict',
            cookieExpiration: 365,
        })

        amplitudeInstance.setVersionName(config.version)

        // Send metrics to our analytics service
        amplitudeInstance.logEvent(`report-web-vitals`, {
            app: {
                name: 'fm-frontend',
                release: config.version,
                stage: config.env,
            },
            page: {
                url: location.href,
                path: location.pathname,
                origin: location.origin,
                name: null,
            },
            report,
        })
        console.debug('report-web-vitals report sent to Amplitude')
    } catch (e) {
        Sentry.captureException(e)
        console.error(e)
    }
}

export type NextWebVitalsMetricsReport = {
    reportedCount: number // Number of times a report has been sent, kinda help to trace how long a same client-side session was
    reportId: string // ID of the "report", helps grouping reports with different data but same reportId together when analysing data
    metrics: {
        FCP?: NextWebVitalsMetrics // First contentful paint, triggers on page load
        FID?: NextWebVitalsMetrics // First input delay, trigger on first end-user interaction (click)
        LCP?: NextWebVitalsMetrics // Largest contentful paint, triggers on first end-user interaction (sometimes doesn't trigger)
        'Next.js-hydration'?: NextWebVitalsMetrics // Triggers on page load
        'Next.js-render'?: NextWebVitalsMetrics // Triggers on client-side redirection (<Link>)
        'Next.js-route-change-to-render'?: NextWebVitalsMetrics // Triggers on client-side redirection (<Link>)
        TTFB?: NextWebVitalsMetrics // Time to first byte, triggers on page load
    }
}

export type UserConsent = {
    isUserOptedOutOfAnalytics?: boolean // Whether the user has been opted out from analytics tracking, whether it's the result of a manual action, or from app's default behaviour
    hasUserGivenAnyCookieConsent?: boolean // Whether the isUserOptedOutOfAnalytics value comes from a manual choice (if not, then it's due to the app's default behaviour)
}
export type NextWebVitalsMetrics = {
    id: string
    label: string
    name: string
    startTime: number
    value: number
}

/**
 * User data stored in the semi-persistent session
 *
 * We use cookies to store our persistent session (instead of browser LocalStorage), so that we may use them from both the client and the server
 *
 * XXX "semi-persistent" stand for "persistent, but may be lost"
 *  As this session is stored in the browser cookies, it may be reused, or it may be lost the next time the user comes back
 */
export type UserSemiPersistentSession = {
    id: string
    deviceId: string
}

/**
 * Patched user data
 *
 * Allow any property allowed in UserSemiPersistentSession,
 * but omit and override those that are required and aren't meant to be updated
 */
export type PatchedUserSemiPersistentSession = {
    // Remove all properties that aren't meant to be overridden
    // Optionally override required properties to make them optional in the patch
} & Omit<UserSemiPersistentSession, 'id' | 'deviceId'>

export type LogEvent = (event: string, data?: any, callback?: Callback) => LogReturn
