import { createContext, useContext, useEffect, useState } from 'react'
import { useQuery } from '../hooks/useQueryHook'

type SearchContextType = {
    searchParams: SearchParams
    setSearchParams: (params: SearchParams) => void
    checkout: CheckoutParams
    setCheckout: (params: CheckoutParams) => void
}

export type CheckoutParams = {
    venueId?: string
    from?: Date | number | string
    to?: Date | number | string
    price?: number
}

export type SearchParams = {
    date: Date | number
    duration: number | null
    location: string
}

export const SearchContext = createContext<SearchContextType>(null)

type Props = {
    children: React.ReactNode
}

export const SearchProvider: React.FC<Props> = ({ children }: Props) => {
    const query = useQuery()

    const [searchParams, setSearchParams] = useState<SearchParams>({
        date: null,
        duration: 1,
        location: '',
    })

    const [checkout, setCheckout] = useState<CheckoutParams>({
        venueId: null,
        from: null,
        to: null,
        price: null,
    })

    useEffect(() => {
        if (query) {
            const { date, duration, location } = query
            setSearchParams({
                date: date ? new Date(date as string) : searchParams.date,
                duration: duration ? Number(duration as string) : searchParams.duration,
                location: (location as string) ?? searchParams.location,
            })
        }
    }, [query])

    return (
        <SearchContext.Provider value={{ searchParams, setSearchParams, checkout, setCheckout }}>
            {children}
        </SearchContext.Provider>
    )
}

export const useSearchContext = (): SearchContextType => useContext(SearchContext) as SearchContextType
