import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { getJwt, storeJwt, removeJwtFromStore } from '../util/auth'

type AuthContextType = {
    jwt: null | string
    setJwt: (jwt: string) => void
    logOut: () => void
    isLoggedIn: boolean
}

export const AuthContext = React.createContext<AuthContextType | null>(null)

type Props = {
    children: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
    const [jwt, setJwtToken] = useState<null | string>(getJwt())
    const [isLoggedIn, setIsLoggedIn] = useState(!!jwt)
    const router = useRouter()

    const setJwt = (token: string): void => {
        storeJwt(token)
        setIsLoggedIn(true)
        setJwtToken(token)
    }

    const logOut = (): void => {
        removeJwtFromStore()
        setIsLoggedIn(false)
        setJwtToken(null)
    }

    React.useEffect(() => {
        if (isLoggedIn) return
        const fromRouter = router.query?.code as string
        const jwtToken = fromRouter || getJwt()
        if (jwtToken) {
            setJwt(jwtToken)
            setIsLoggedIn(true)
        }
    }, [])

    return <AuthContext.Provider value={{ isLoggedIn, setJwt, logOut, jwt }}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => React.useContext(AuthContext) as AuthContextType
