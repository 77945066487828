import * as Sentry from '@sentry/node'
import config from '../../util/config'
import { UserSemiPersistentSession } from '../amplitude/amplitude'

if (config.sentryDsn) {
    Sentry.init({
        dsn: config.sentryDsn,
        enabled: config.env === 'production' || config.env === 'staging',
        environment: config.env,
        release: config.version,
    })
}

export const configureSentryUser = (userSession: UserSemiPersistentSession): void => {
    if (process.env.SENTRY_DSN) {
        Sentry.configureScope((scope) => {
            scope.setTag('userId', userSession?.id)
            scope.setTag('userDeviceId', userSession?.deviceId)
            scope.setContext('user', userSession)
        })
    }
}

export default Sentry
