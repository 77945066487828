import { AmplitudeClient } from 'amplitude-js'
import { AnimatePresence } from 'framer-motion'
import { NextComponentType, NextPageContext } from 'next'
import Link from 'next/link'
import { Router, useRouter } from 'next/router'
import CookieConsent from 'react-cookie-consent'
import { SearchProvider } from 'src/contexts/SearchContext'
import { DebugHelper } from '../components/global/DebugHelper'
import { AuthProvider, useAuth } from '../contexts/AuthContext'
import { getAmplitudeInstance, UserSemiPersistentSession } from '../modules/amplitude/amplitude'
import amplitudeContext from '../modules/amplitude/amplitudeContext'
import UniversalCookiesManager from '../modules/cookiesManager/UniversalCookiesManager'
import { createLogger } from '../util/logger'
import Head from 'next/head'

import { Amplitude, AmplitudeProvider } from '@amplitude/react-amplitude'
import config, { isProd, isServer } from '../util/config'
import { configureSentryUser } from '../modules/sentry/sentry'
import { useEffect } from 'react'

const fileLabel = 'app/AppBootstrap'
const logger = createLogger({
    label: fileLabel,
})
export const AppBootstrap: React.FC<Props> = (props) => {
    const PageComponent: any = props.Component
    const pageProps = props.pageProps
    const router = useRouter()
    const getLayout = PageComponent.getLayout || ((page) => page)
    const Component = getLayout(<PageComponent {...pageProps} />)
    const { isLoggedIn } = useAuth()

    useEffect(() => {
        if (!isLoggedIn && PageComponent?.authRequied) {
            logger.info('REDIRECTED')
            router.push('/')
        }
    }, [isLoggedIn])

    return (
        <>
            {Component}
            <DebugHelper />
            <CookieConsent
                buttonText="Elfogadom"
                buttonStyle={{ color: '#4e503b', fontSize: '13px', borderRadius: '4px' }}
            >
                A weboldalunkon cookie-kat használunk, hogy a legjobb felhasználói élményt nyújthassuk.
                <Link href="/cookie_szabalyzat">
                    <p className="text-blue-600 ">További információ</p>
                </Link>
            </CookieConsent>
            <div id="portal-root" />
        </>
    )
}

export const _AppBootstrap: React.FC<Props> = ({ Component, ...pageProps }) => {
    const cookiesManager: UniversalCookiesManager = new UniversalCookiesManager()
    const userSession: UserSemiPersistentSession = cookiesManager.getUserData()

    const amplitudeInstance: AmplitudeClient = getAmplitudeInstance({ userId: userSession.id })
    if (!isProd && !isServer) {
        window['amplitudeInstance'] = amplitudeInstance
        window['router'] = pageProps.router
    }

    configureSentryUser(userSession)

    let path = null
    if (!isServer) {
        path = {
            href: location.href,
            path: location.pathname,
            origin: location.origin,
        }
    }

    return (
        <>
            <Head>
                <title>Foglaljmost.hu</title>
            </Head>
            <AmplitudeProvider
                amplitudeInstance={amplitudeInstance}
                apiKey={config.amplitudeKey}
                userId={userSession.id}
            >
                <Amplitude
                    eventProperties={{
                        app: {
                            name: 'fm-frontend',
                            release: config.version,
                            stage: config.env,
                            preset: process.env.NEXT_PUBLIC_NRN_PRESET,
                        },
                        page: {
                            url: path?.href,
                            path: path?.pathname,
                            origin: path?.origin,
                            name: null,
                        },
                    }}
                >
                    <amplitudeContext.Provider value={{ amplitudeInstance }}>
                        <SearchProvider>
                            <AuthProvider>
                                <Component {...pageProps} />
                            </AuthProvider>
                        </SearchProvider>
                    </amplitudeContext.Provider>
                </Amplitude>
            </AmplitudeProvider>
        </>
    )
}

export type MultiversalAppBootstrapProps<PP extends MultiversalPageProps = MultiversalPageProps> = {
    Component?: NextComponentType<NextPageContext> // Page component, not provided if pageProps.statusCode is 3xx or 4xx
    err?: Error
    pageProps?: PP
    router?: Router

    __N_SSG?: boolean
    __N_SSR?: boolean
    __N_SSP?: boolean
}

export type MultiversalPageProps<E extends Record<string, any> = Record<string, any>> = {
    error?: Error
    isReadyToRender?: boolean
    statusCode?: number
} & E

type Props = MultiversalAppBootstrapProps<SSRPageProps>

export type SSRPageProps<E extends OnlyServerPageProps = OnlyServerPageProps> = {
    isServerRendering?: boolean
    isQuickPreviewPage?: boolean
} & MultiversalPageProps &
    Partial<MultiversalAppBootstrapPageProps> &
    E

export type OnlyServerPageProps = {
    headers?: PublicHeaders
    readonlyCookies?: Cookies
    userSession?: UserSemiPersistentSession
}

export type PublicHeaders = {
    'accept-language'?: string
    'user-agent'?: string
    host?: string
}

export type MultiversalAppBootstrapPageProps = {
    isSSGFallbackInitialBuild: boolean
}

export type Cookies = {
    [key: string]: any
}
