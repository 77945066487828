import winston from 'winston'
import config, { isProd } from './config'

const { format, transports } = winston
const { combine, timestamp, printf, splat, colorize } = format

const myFormat = printf((info) => {
    let meta = ''
    if (info.meta) {
        meta = ['string', 'number'].includes(typeof info.meta) ? `[${info.meta}]` : JSON.stringify(info.meta, null, 2)
    }

    return `${info.timestamp} [${info.label}] ${info.level}: ${meta} ${info.message}` // Display complex type as json
})

export const createLogger = ({
    label,
    defaultLevel = 'info',
}: {
    label: string
    defaultLevel?: string
}): winston.Logger => {
    const envLevel = isProd ? 'error' : 'debug'
    const level = defaultLevel || envLevel

    return winston.createLogger({
        level,
        format: combine(colorize(), format.label({ label }), timestamp(), splat(), myFormat),
        transports: [new transports.Console()],
    })
}
