import { AmplitudeClient } from 'amplitude-js'
import React from 'react'

export type AmplitudeContext = {
    amplitudeInstance?: AmplitudeClient
}

const initialContext = {}

export const amplitudeContext = React.createContext<AmplitudeContext>(initialContext)

export default amplitudeContext
